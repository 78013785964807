@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
  background-color: #202020;
  background-image: url(/assets/wave.svg);
  // background-size: 100%
  background-repeat: no-repeat; }

* {
  font-family: 'Poppins', sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #fff; }

a {
  color: inherit;
  text-decoration: none;
  transition: all .2s ease-in-out;

  &:hover {
    color: #1694be; } }

ul {
  list-style: none; }
