.btn {
    background: #1bb2e4;
    padding: .6em 1.2em;
    border-radius: 27px;
    // transition: all .2s ease-in-out
    font-weight: 600;

    &:hover {
        background: #1694be; } }

.header {
    position: absolute;
    padding: 2em 2em;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center; }

.header-image {
    height: 2em;
    width: auto; }

@media screen and (min-width: 1024px) {
    .header {
        padding: 3em 10em; } }

.hero {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    padding: 0 2em; }

.hero-title {
    font-size: 1.8em; }

.hero-buttons {
    margin-top: 1em;
    display: flex;
    justify-content: flex-start;
    align-self: center; }

.hero-btn {
    background: none;
    border: 1px solid#1bb2e4;
    margin-right: 1em;
    // transition: all .2s ease-in-out

    &:hover {
        border: 1px solid#fff;
        background: #1bb2e4; } }

.hero-image {
    height: 6em;
    margin-top: 5em; }

@media screen and (min-width: 1024px) {
    .hero-title {
        font-size: 1.5em; } }

@media screen and (min-width: 1200px) {
    .hero-title {
        font-size: 2em; } }

@media screen and (min-width: 1400px) {
    .hero-title {
        font-size: 3em; }

    .hero-btn {
        font-size: 1.3em;
        padding: .7em 2em; }

    .hero-image {
        height: 6em; } }

@media screen and (min-width: 1600px) {
    .hero-title {
        font-size: 3.5em; }

    .hero-image {
        height: 12em; } }

@media screen and (min-width: 1024px) {
    .hero {
        flex-direction: row; }

    .hero-section {
        max-width: 670px;
        margin: 10em; }

    .hero-image {
        margin: 0; } }

.benefits {
    margin-top: 10em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }

.benefits-title {
    font-size: 2em;
    font-weight: 600; }

.benefits-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 2em; }

.benefits-wrapper {
    width: 100%;
    border: 1px solid #1bb2e4;
    border-radius: 1.2em;
    margin-top: 2em;
    padding: 1em 2em;
    text-align: center; }

@media screen and (min-width: 1024px) {
    .benefits-list {
        max-width: 600px;
        flex-direction: row;
        align-items: stretch;
        gap: 2em;
        margin-top: 2em; }

    .benefits-title {
        font-size: 2.5em; }

    .benefits-wrapper {
        width: auto; } }

.footer {
    margin-top: 15em;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2em; }

.footer-text-primary {
    font-weight: 600;
    font-size: 1.2em; }

.footer-text-secondary {
    font-weight: 600;
    margin-top: .4em;
    font-size: 1.1em; }
